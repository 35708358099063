import { Box, Grid } from "@mui/material";
import { Navigate, Outlet, useLocation } from "react-router";
import SideNav from "../components/sideNav/SideNav";
import { Navbar } from "../components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_KEY } from "../pages/auth/authSlice";

export default function PrivateRoutes() {
    const location = useLocation();
    
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state[AUTH_KEY]).isAuth;

    const [navbarHeight, setNavbarHeight] = useState(0);

	useEffect(() => {
		const navbar = document.getElementById("navbar");
    
		if (navbar) {
			setNavbarHeight(navbar.offsetHeight);
		}
	}, []);

    return (
        isAuth ? (
            <main>
                <Grid container>
                    <Grid 
                        className="side-nav-wr"
                        item xs={2}
                    >
                        <SideNav />
                    </Grid>
                    <Grid item xs={10}>
                        <Navbar />
                        <Box 
                            className="site-content-wr"
                            sx={{ height: `calc(100vh - 102px)` }}
                        >
                            <Outlet />
                        </Box>
                    </Grid>
                </Grid>
            </main>
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    )
}