import { useDispatch } from "react-redux";
import { Button, Stack } from "@mui/material";
import { IconDeviceMobile, IconMail } from "@tabler/icons-react";
import { setAuthStep, setAuthVia } from "../authSlice";

export default function AuthStep1() {
    const dispatch = useDispatch();

    const handleAuthStep1 = (authVia) => {
        localStorage.setItem("auth-mode", authVia);
        dispatch(setAuthVia(authVia));
        dispatch(setAuthStep(2));
    }

    return (
        <Stack 
            className="auth-step-1"
            direction="column"
            spacing={3}
        >
            <Button 
                variant="outlined"
                fullWidth
                onClick={() => handleAuthStep1("text")}
            >
                <IconDeviceMobile size={20} stroke={2} />
                <span>Continue with mobile no.</span>
            </Button>

            <Button 
                variant="outlined"
                fullWidth
                onClick={() => handleAuthStep1("email")}
            >
                <IconMail size={20} stroke={2} />
                <span>Continue with email</span>
            </Button>
        </Stack>
    )
}