import Request from '../../utils/api';
import { USER_ORGS } from '../../utils/apiRoutes';

const api = new Request();

export const fetchUserOrgs = async (params, onSuccess, onError) => {
    const response = await api.get(USER_ORGS, params);

    if (response.message === "Success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};
