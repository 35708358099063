import React from 'react';
import { Box, Button, Grid, Typography, Stack, Popover, Skeleton } from '@mui/material';

function OverviewSkeleton() {
	return (
		<Box margin={"16px"} >
			<Box sx={{ marginTop: '16px' }}>
				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
				>
					<Skeleton
						sx={{ padding: '8px' }}
						width={210}
						height={60}
						animation="wave"
						variant="rectangular"
					/>
					<Skeleton
						sx={{ marginLeft: '20px', padding: '8px' }}
						width={210}
						height={60}
						animation="wave"
						variant="rectangular"
					/>
				</Stack>
			</Box>

			<Box
				sx={{ marginTop: '16px' }}
			>
				<Grid
					container
					columnSpacing={3}
					rowSpacing={3}
				>
					<Grid
						item
						xs={2}
					>
						<Box>
							<Skeleton
								sx={{ padding: '8px' }}
								height={'160px'}
								animation="wave"
								variant="rectangular"
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={2}
					>
						<Box>
							<Skeleton
								sx={{ padding: '8px' }}
								height={'160px'}
								animation="wave"
								variant="rectangular"
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={2}
					>
						<Box>
							<Skeleton
								sx={{ padding: '8px' }}
								height={'160px'}
								animation="wave"
								variant="rectangular"
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={2}
					>
						<Box>
							<Skeleton
								sx={{ padding: '8px' }}
								height={'160px'}
								animation="wave"
								variant="rectangular"
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<Box>
							<Skeleton
								sx={{ padding: '8px' }}
								height={'160px'}
								animation="wave"
								variant="rectangular"
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Box
				sx={{ marginTop: '16px' }}
			>
				<Grid
					container
					columnSpacing={3}
				>
					<Grid
						item
						xs={6}
					>
						<Skeleton
							sx={{ padding: '8px' }}
							width={'100%'}
							height={'450px'}
							animation="wave"
							variant="rectangular"
						/>
					</Grid>
					<Grid
						item
						xs={6}
					>
						<Skeleton
							sx={{ padding: '8px' }}
							width={'100%'}
							height={'450px'}
							animation="wave"
							variant="rectangular"
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default OverviewSkeleton;
