import { createSlice } from "@reduxjs/toolkit";

export const USER_KEY = "user";

export const userSlice = createSlice({
    name: USER_KEY,
    initialState: {
        userData: null,
        currUserOrgId: null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setCurrUserOrgId: (state, action) => {
            state.currUserOrgId = action.payload;
        },
    },
});

export const { setUserData, setCurrUserOrgId } = userSlice.actions;
export default userSlice.reducer;