import Request from '../../utils/api';
import { ASSISTANTS_LIST } from '../../utils/apiRoutes';

const api = new Request();
export const getAssistantsList = async () => {
	const response = await api.get(
		ASSISTANTS_LIST, 
		{isActive: true}
	);
	return response;
};
