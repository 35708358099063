import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import { getBrowserLocation, getCountryIso } from "../../../utils/location";
import { AUTH_KEY, setAuthStep, setAuthVia, setEmail, setPhoneData } from "../authSlice";
import { getVerificationCode } from "../apiFunctions";
import 'react-phone-input-2/lib/material.css'

export default function AuthStep2 () {
    const CAPTCH_SITE_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY;

    const [isCaptchaToken, setIsCaptchaToken] = useState(null);
    const [country, setCountry] = useState("us");
    const [sendingCode, setSendingCode] = useState(false);

    const dispatch = useDispatch();
    const authState = useSelector((state) => state[AUTH_KEY]);
    const { 
        authVia,
        phoneCountryCode,
        phoneNumber,
        email,
    } = authState;

    const handlePhoneInputChange = (value, data) => {
        const countryCode = data.dialCode;

        const pattern = new RegExp(`^(${countryCode})(.*)$`);
        const match = value.match(pattern);

        dispatch(
            setPhoneData({
                phoneCountryCode: countryCode,
                phoneNumber: match[2]
            })
        );
    }

    const checkFormat = (inp) => {
        let regex = 0;
        let errorMsg = "";

        if (authVia === "text") {
            regex = /^\d{10}$/;
            errorMsg = "Please enter a valid phone number and try again.";
        } else if (authVia === "email") {
            regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            errorMsg = "Please enter a valid email address and try again.";
        }

        if (!regex.test(inp)) {
            toast.error(errorMsg);
            return false;
        } else {
            return true;
        }
    }

    const onGetVerificationCodeSuccess = () => {
        toast.success(`Verification code sent to your ${authVia === "text" ? "phone" : "email"}`);
        setSendingCode(false);
        dispatch(setAuthStep(3));
    }

    const onGetVerificationCodeError = () => {
        toast.error("Error getting verification code. Check credentials and try again.");
        setSendingCode(false);
    }

    const handleGetVerificationCode = () => {
        // Check if phone number or email is in correct format
        if (!checkFormat(authVia === "text" ? phoneNumber : email)) {
            return;
        }

        // temp code [WIP]
        if (!isCaptchaToken) {
            toast.error("Please verify captcha and try again.");
            return;
        }
        
        // Send verification code
        setSendingCode(true);
        let postPayload = {communicationType: authVia};

        if (authVia === "text") {
            postPayload = {
                ...postPayload,
                phoneContact: {
                    countryCode: phoneCountryCode,
                    number: phoneNumber
                }
            }
        } else if (authVia === "email") {
            postPayload = {
                ...postPayload,
                emailContact: {
                    email: email
                }
            }
        }

        getVerificationCode(postPayload, onGetVerificationCodeSuccess, onGetVerificationCodeError);
    }

    const disableVerificationCodeButton = () => {
        if (authVia === "text") {
            return !phoneCountryCode || !phoneNumber;
        } else if (authVia === "email") {
            return !email;
        }
    }

    const handleGoToPrevStep = () => {
        localStorage.removeItem("auth-mode");
        dispatch(setAuthStep(1));
        dispatch(setAuthVia(null));
    }

    useEffect(() => {
        if (authVia === "text") {
            getBrowserLocation((coords) => {
                getCountryIso(
                    coords.lat, 
                    coords.lon, 
                    (countryCode) => setCountry(countryCode)
                );
            });
        }
    }, [authVia]);

    return (
        <Stack
            className="auth-step-2"
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Typography 
                className="auth-step-prompt"
                variant="body2"
                gutterBottom
            >
                Enter your phone number to receive the verification code
            </Typography>

            {
                authVia === "text" && (
                    <PhoneInput
                        country={country}
                        specialLabel="Phone Number"
                        prefix="+"
                        countryCodeEditable={false}
                        autoFormat={true}
                        inputClass="auth-phone-input"
                        inputStyle={{ width: '100%' }}
                        inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                        }}
                        enableSearch={true}
                        searchPlaceholder="Search Country"
                        searchStyle={{ width: '90%' }}
                        value={String(phoneCountryCode + phoneNumber)}
                        onChange={(value, data) => handlePhoneInputChange(value, data)}
                    />
                )
            }
            {
                authVia === "email" && (
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => dispatch(setEmail(e.target.value))}
                            label="Email"
                            required
                            autoFocus
                        />
                    </FormControl>
                )
            }

            <ReCAPTCHA 
                sitekey={CAPTCH_SITE_KEY}
                onChange={(token) => setIsCaptchaToken(token)}
            />

            <Button 
                variant="contained" 
                fullWidth
                disabled={sendingCode || !isCaptchaToken || disableVerificationCodeButton()}
                onClick={handleGetVerificationCode}
            >
                {sendingCode && <CircularProgress size={16} />}
                <span>{sendingCode ? "Sending Verification Code..." : "Get Verification Code"}</span>
            </Button>
            <Button
                variant="text"
                fullWidth
                onClick={handleGoToPrevStep}
            >
                Back
            </Button>
        </Stack>
    );
}