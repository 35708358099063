import React, { useEffect, useState } from 'react';
import AssistantsCard from './components/AssistantsCard';
import { Stack, Box, Fade } from '@mui/material';
import { getAssistantsList } from './commonFunctions';
import { TRANSITION_TIME_OUT } from '../../utils/constants';
import AssistantsSkeleton from "./components/AssistantsSkeleton";

function Assistants() {
    const [assistantsList, setAssistantsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAssistants = async () => {
        const response = await getAssistantsList();
        if (response?.status?.status) {
            const { data } = response;
            setAssistantsList(data);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchAssistants();
    }, [])

    if (isLoading) {
        return <AssistantsSkeleton />
    }

    return (
        <Fade
            in={true}
            timeout={2500}
        >
            <Box
                padding={2}
            >
                <Stack
                    direction={'column'}
                    spacing={2}
                >
                    {
                        assistantsList.map((assistant, idx) => (
                            assistant.isActive ? (
                                <AssistantsCard index={idx + 1} key={assistant.id} data={assistant} />
                            ) : null
                        ))
                    }
                </Stack>
            </Box>
        </Fade>
    )
}

export default Assistants
