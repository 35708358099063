import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Stack, Typography } from '@mui/material';
import { AUTH_KEY, setAuthStep, setAuthVia } from './authSlice';
import { AuthStep1, AuthStep2, AuthStep3 } from "./components";
import thirdvoiceSecLogo from "../../assets/logos/thirdvoice_sec.svg";
import "./auth.scss";

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();
    const authState = useSelector((state) => state[AUTH_KEY]);
    const { isAuth, authStep } = authState;

    useEffect(() => {
        const savedAuthMode = localStorage.getItem("auth-mode");
        if (savedAuthMode) {
            dispatch(setAuthVia(savedAuthMode));
            dispatch(setAuthStep(2));
        }
    }, []);

    useEffect(() => {
        if (isAuth) {
            isAuth && navigate(location?.state?.from ? location.state.from : "/overview", { replace: true });
        }
    }, [isAuth]);

    return (
        <Stack
            id="login"
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <Paper
                className='auth-container'
                elevation={2}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={6}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <img
                            className='auth-logo'
                            src={thirdvoiceSecLogo}
                            alt="Thirdvoice Logo"
                        />
                        <Typography component="p" variant="h5" fontWeight="600">Welcome</Typography>
                        <Typography variant="body1" fontWeight="500" sx={{ marginTop: "4px" }}>Log in to thirdvoice.AI</Typography>
                    </Stack>

                    {authStep === 1 && <AuthStep1 />}
                    {authStep === 2 && <AuthStep2 />}
                    {authStep === 3 && <AuthStep3 />}
                </Stack>
            </Paper>
        </Stack>
    )
}