import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import SiteRoutes from './routes/siteRoutes';
import './App.scss';

function App() {
	return (
		<Box className="App">
			<SiteRoutes />

			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				theme="light"
			/>
		</Box>
	);
}

export default App;
