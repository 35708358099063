import { AppBar, Box, MenuItem, Select, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrUserOrgId, USER_KEY } from "../../redux/userSlice";
import { fetchUserOrgs } from "./apiFunction";
import { toast } from "react-toastify";
import { IconChevronDown } from "@tabler/icons-react";
import { is } from "date-fns/locale";

export default function Navbar() {
    const [userOrgs, setUserOrgs] = useState(null);
    const [isUserOrgsLoading, setIsUserOrgsLoading] = useState(false);

    const dispatch = useDispatch();
    const userState = useSelector(state => state[USER_KEY]);
    const { userData, currUserOrgId } = userState;

    const getUserOrgsSuccess = (data) => {
        setUserOrgs(data);
        dispatch(setCurrUserOrgId(data[0].orgId));
        setIsUserOrgsLoading(false);
    }

    const getUserOrgsError = (error) => {
        toast.error("Unable to fetch user organizations.");
        setIsUserOrgsLoading(false);
    }

    const getUserOrgs = (userData) => {
        setIsUserOrgsLoading(true);

        fetchUserOrgs(
            { userId: userData.userId },
            getUserOrgsSuccess,
            getUserOrgsError
        )
    }

    useEffect(() => {
        if (userData) {
            getUserOrgs(userData);
        }
    }, [])

    return (
        <AppBar
            id="navbar"
            position="sticky"
            elevation={0}
        >
            <Box>
                {
                    userOrgs && !isUserOrgsLoading ? (
                        <Select 
                            className="navbar-select"
                            placeholder="Select Organization"
                            value={currUserOrgId}
                            IconComponent={() => 
                                <Stack sx={{ paddingRight: "12px" }}>
                                    <IconChevronDown className="icon" size={20} />
                                </Stack>
                            }
                            onChange={(e) => dispatch(setCurrUserOrgId(e.target.value))}
                        >
                            {
                                userOrgs.map((org, index) => (
                                    <MenuItem key={index} value={org.orgId}>{org.orgName}</MenuItem>
                                ))
                            }
                        </Select>
                    ) : (
                        <Skeleton 
                            variant="rectangular" 
                            width={300} 
                            height={34} 
                            animation="wave"
                            sx={{ borderRadius: "8px" }}
                        />
                    )
                }
            </Box>
        </AppBar>
    );
}