import { createSlice } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { setIntialState } from './commonFunction';

export const selectedOrgSlice = createSlice({
	name: 'dateSlice',
	initialState: setIntialState(),
	reducers: {
		updateDate: (state, action) => {
			state[0] = action.payload[0];
		},
	},
});

export const { updateDate } = selectedOrgSlice.actions;
export default selectedOrgSlice.reducer;
