import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography, Divider, Grid, Tooltip, IconButton } from '@mui/material';
import { COLORS } from '../../../utils/constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from '../../../utils/common';
import { IconCopy } from '@tabler/icons-react';

function AssistantsCard({ index, data }) {
	const { name, phoneNumber } = data;
	const { LIGHT_GREY, TEXT_GREY } = COLORS;
	const [isCopied, setIsCopied] = useState({ entity: null, copied: false });
	const assistantsSummary = data.model.messages[0].content || '-';

	return (
		<Stack
			padding={2}
			borderRadius={2}
			backgroundColor={'white'}
			spacing={2}
		>
			<Typography
				variant="h6"
				component={'h6'}
			>
				Assistant - {index}
			</Typography>
			<Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />
			<Stack
				direction={'column'}
				spacing={2}
				marginTop={'8px'}
			>
				<Grid container>
					<Grid
						item
						xs={2}
					>
						<Typography color={TEXT_GREY} variant="body2">Assistant Name</Typography>
					</Grid>
					<Grid
						item
						borderRadius={2}
						bgcolor={LIGHT_GREY}
						paddingTop={1}
						paddingBottom={1}
						paddingRight={2}
						paddingLeft={2}
					>
						<Typography variant="body2">{name}</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={2}
					>
						<Typography color={TEXT_GREY} variant="body2">Phone Number</Typography>
					</Grid>
					<Grid
						item
						borderRadius={2}
						bgcolor={LIGHT_GREY}
						paddingTop={1}
						paddingBottom={1}
						paddingRight={2}
						paddingLeft={2}
					>
						<Typography variant="body2">{phoneNumber ? phoneNumber : '-'} </Typography>
					</Grid>
					<Stack
						direction={'column'}
						justifyContent={'center'}
						alignItems={'center'}
						marginLeft={'4px'}
					>
						<Tooltip 	
							title={isCopied.copied && isCopied.entity === "phone_number" ? "Number copied!" : "Copy Number"}
							placement="right"
							arrow
						>
							<IconButton onClick={() => copyTextToClipboard(phoneNumber, setIsCopied, "phone_number")}>
								<IconCopy size={16} stroke={1.5} />
							</IconButton>
						</Tooltip>
					</Stack>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={2}
					>
						<Typography color={TEXT_GREY} variant="body2">Assistant Summary</Typography>
					</Grid>
					<Grid
						item
						borderRadius={2}
						bgcolor={LIGHT_GREY}
						xs={10}
						maxHeight={'200px'}
						sx={{
							overflowX: 'hidden',
							overflowY: 'scroll',
						}}
						paddingTop={1}
						paddingBottom={1}
						paddingRight={2}
						paddingLeft={2}
					>
						<Stack
							direction="column"
							spacing={1}
						>
							
							{
								assistantsSummary.split("\n\n").map((summary, idx) => (
									<Typography key={idx} variant="body2" sx={{ lineHeight: '1.5' }}>{summary}</Typography>
								))
							}
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	);
}

export default AssistantsCard;
