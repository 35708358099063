import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';

function PieGraph({ data }) {
	// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
	const COLORS = ['#D3A0B9', '#BB6C92', '#88A4D7', '#5578B8'];
	const RADIAN = Math.PI / 180;
	const [activeIndex, setActiveIndex] = useState(0);

	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor={x > cx ? 'start' : 'end'}
				dominantBaseline="central"
			>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#BB6C92"} fontWeight={500}>
					{payload.title}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={"#BB6C92"}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					// fill={fill}
					fill={"#BB6C92"}
				/>
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#BB6C92">{`Calls ${value}`}</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#BB6C92">
					{`(Rate ${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
	}

	return (
		<Box
			style={{
				// height: '100%',
				backgroundColor: 'white',
				padding: '16px',
				borderRadius: '8px',
			}}
		>
			<Typography
				align="center"
				sx={{ fontSize: '16px', marginBottom: '16px' }}
			>
				{'Average Number Of New Patient Leads By Day'}
			</Typography>
			<ResponsiveContainer
				width={'100%'}
				height={380}
				style={{
					backgroundColor: 'white',
				}}
			>
				<PieChart>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						labelLine={false}
						activeIndex={activeIndex}
						label={renderCustomizedLabel}
						activeShape={renderActiveShape}
						// outerRadius={140}
						// fill="#8884d8"
						fill='#D3A0B9'
						dataKey="count"
						onMouseEnter={onPieEnter}
						innerRadius={60}
						outerRadius={140}
					>
						{/* {data.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))} */}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</Box>
	);
}

export default PieGraph;



