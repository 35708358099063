import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Chip, CircularProgress, Fade, Icon, Paper, Popover, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { PlayCircleOutlineRounded } from "@mui/icons-material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from "react-toastify";
import moment from "moment/moment";
import { DateRangePicker } from "react-date-range";
import { USER_KEY } from "../../redux/userSlice";
import { CALL_LOGS_KEY, setCallDetails, setDateRange } from "./callLogsSlice";
import { fetchCallLogs } from "./apiFunctions";
import CallDetails from "./components/CallDetails";
import { getLeadStateColor, setDateRangeObj } from "./utilFunctions";
import { TRANSITION_TIME_OUT } from "../../utils/constants";
import "./callLogs.scss";
import { set } from "date-fns";


function LogCellSkeleton () {
    return (
        <Skeleton variant="text" width={"50%"} height={24} animation="wave" />
    ); 
}

export default function CallLogs() {
    const tableHeaders = ["Lead", "Caller Name", "Phone No.", "Date & Time", "Listen", "Status"];

    const [callLogs, setCallLogs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [btnText, setBtnText] = useState('Last 30 days');
	const [range, setRange] = useState(setDateRangeObj());

    const navigate = useNavigate();  
    const { id } = useParams();

    const dispatch = useDispatch();
    const userState = useSelector(state => state[USER_KEY]);
    const { dateRange } = useSelector(state => state[CALL_LOGS_KEY]);

    const handleOpenDatePicker = (e) => {
		setShowDatePicker((state) => !state);
		setAnchorEl(e.currentTarget);
	};

    const handleDatePickerClose = () => {
		setRange(dateRange);
		setShowDatePicker(false);
	};

    const handleSelectDate = () => {
		const { startDate, endDate } = range[0];
		let sd = new Date(startDate);
		let ed = new Date(endDate);
		const btnTxt = "" + formatDate(sd) + " - " + formatDate(ed)
		setBtnText(btnTxt)
		dispatch(setDateRange(range));
		setShowDatePicker(false);
	};

    const handleClearDate = () => {
        dispatch(setDateRange(setDateRangeObj()));
        setRange(setDateRangeObj());
        setBtnText('Last 30 days');
    };

    const formatDate = (date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		let day = date.getDate();
		let month = months[date.getMonth()];
		let year = date.getFullYear();

		// Ensure day is two digits
		if (day < 10) {
			day = '0' + day;
		}

		return `${month} ${day}, ${year}`;
	}

    const setDateParams = () => {
        const from = moment(range[0].startDate).format("YYYY-MM-DD");
        const to = moment(range[0].endDate).format("YYYY-MM-DD");

		const params = {
			fromDate: from,
			toDate: to,
		};

		return params;
	};

    const getCallDuration = (startedAt, endedAt, index) => {
        let start = moment(startedAt);
        let end = moment(endedAt);

        let duration = moment.duration(end.diff(start));

        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();

        if (hours === 0 && minutes === 0 && seconds === 0) {
            return null;
        }

        return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""} ${seconds ? seconds + "s" : ""}`;
    };

    const onGetCallLogsSuccess = (data) => {
        setCallLogs(data);
        setLoading(false);
    };

    const onGetCallLogsError = (error) => {
        toast.error("Unable to fetch call logs");
        setLoading(false);
    };

    const getCallLogs = (dateParams) => {
        setLoading(true);

        let params = {};
        if (userState.currUserOrgId) {
            params = { 
                orgId: userState.currUserOrgId,
                limit: 250,
                isActive: true,
            };

            if (dateParams) {
                params = { ...params, ...dateParams };
            }
        }

        fetchCallLogs(params, onGetCallLogsSuccess, onGetCallLogsError);
    };

    const openCallDetailsModal = (log) => {
        dispatch(setCallDetails({ openCallDetails: true, currentCallDetails: log }));
        navigate(`/call-logs/${log.id}`);
    };

    useEffect(() => {
        const dateParams = setDateParams();
        getCallLogs(dateParams);
    }, [dateRange]);

    useEffect(() => {
        if (id && callLogs) {
            let call = callLogs.find(log => log.id === id);
            if (call) {
                openCallDetailsModal(call);
            }
        }
    }, [callLogs, id]);

    return (
        <Box id="call-logs">
            <Paper 
                className="call-logs-wr"
                component={Stack}
                direction="column"
            >
                <Toolbar className="cl-toolbar">
                    <Stack
                        direction="column"
                        spacing={1}
                        width="100%"
                    >
                        <Typography component="h2" variant="h6" fontWeight={500}>
                            Call Logs
                        </Typography>

                        <Stack 
                            direction="row"
                            alignItems="center"
                        >
                            <Fade
                                in={true}
                                timeout={TRANSITION_TIME_OUT}
                            >
                                <Box>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography variant="body2">Display data from</Typography>
                                        <Button
                                            sx={{ marginLeft: '8px', backgroundColor: 'white' }}
                                            size="small"
                                            onClick={handleOpenDatePicker}
                                            variant="outlined"
                                            endIcon={showDatePicker ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        >
                                            <Typography 
                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                variant="body2"
                                            >
                                                {btnText}
                                            </Typography>
                                        </Button>
                                        <Popover
                                            sx={{
                                                marginTop: '8px',
                                            }}
                                            anchorEl={anchorEl}
                                            open={showDatePicker}
                                            onClose={handleDatePickerClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography>
                                                <DateRangePicker
                                                    value={range}
                                                    onChange={(item) => setRange([item.selection])}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    months={2}
                                                    ranges={range}
                                                    direction="horizontal"
                                                    rangeColors={['#9E2D63', '#9E2D63', '#D44D4D']}
                                                    maxDate={new Date()}
                                                />
                                                <Stack
                                                    direction={'row'}
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                    alignItems={'space-around'}
                                                    padding={'8px'}
                                                    sx={{
                                                        marginBottom: '12px',
                                                    }}
                                                >
                                                    <Button
                                                        sx={{ marginLeft: '8px' }}
                                                        size="medium"
                                                        variant="contained"
                                                        onClick={handleSelectDate}
                                                    >
                                                        Done
                                                    </Button>
                                                    <Button
                                                        sx={{ marginLeft: '8px' }}
                                                        size="medium"
                                                        variant="outlined"
                                                        onClick={handleDatePickerClose}
                                                    >
                                                        Close
                                                    </Button>
                                                </Stack>
                                            </Typography>
                                        </Popover>
                                    </Stack>
                                </Box>
                            </Fade>

                            <Button 
                                variant="text"
                                size="small"
                                disabled={btnText === 'Last 30 days'}
                                sx={{ color: 'text.primary' }}
                                onClick={handleClearDate}
                            >
                                Clear 
                            </Button>
                        </Stack>
                    </Stack>
                </Toolbar>
                
                <TableContainer className="cl-table-cn">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {
                                    tableHeaders.map((header, index) => (
                                        <TableCell key={index}>{header}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                callLogs && !loading ? (
                                    callLogs?.length > 0 ? (
                                        callLogs.map((log, index) => (
                                            <TableRow
                                                className="cl-table-row"
                                                key={index}
                                                hover
                                                onClick={() => openCallDetailsModal(log)}
                                            >
                                                <TableCell>
                                                    {
                                                        log.leadStatus ? 
                                                        (
                                                            <Chip
                                                                label={(log.leadStatus).split(" ")[0]}
                                                                variant="outlined"
                                                                color={getLeadStateColor(log.leadStatus)}
                                                            />
                                                        ) : 
                                                        "--"
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {log.callerName ? log.callerName : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {log.customer?.number ? log.customer.number : "--"}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        log.createdAt ? 
                                                            <Stack direction="column" spacing={0.5}>
                                                                <Typography variant="body2">{moment(log.createdAt).format("MMM D, YYYY")}</Typography>
                                                                <Typography variant="body2">{moment(log.createdAt).format("hh:mm A")}</Typography>
                                                            </Stack> : 
                                                            "--"
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        getCallDuration(log.startedAt, log.endedAt, index) ? (
                                                            <Stack
                                                                direction="column"
                                                                spacing={0.5}
                                                            >
                                                                <Icon color="primary">
                                                                    <PlayCircleOutlineRounded />
                                                                </Icon>
                                                                <Typography variant="body2">
                                                                    {getCallDuration(log.startedAt, log.endedAt, index)}
                                                                </Typography>
                                                            </Stack>
                                                        ) : (
                                                            <Typography variant="body2">--</Typography>
                                                        )
                                                    }
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" textTransform="capitalize">
                                                        {log.status ? log.status : "--"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={tableHeaders.length} align="center">No call logs found!</TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    new Array(10).fill(0).map((_, index) => (
                                        <TableRow key={index}>
                                            {
                                                tableHeaders.map((_, index) => (
                                                    <TableCell key={index}>
                                                        <LogCellSkeleton />
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <CallDetails />
        </Box>
    );
}
