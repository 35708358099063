import { Warning } from "@mui/icons-material";
import { colors, createTheme } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const PRIMARY_COLOR = "#9E2D63";
const ACCENT_COLOR = "#F5EDF1";

const TEXT_PRI = "#252525";
const TEXT_SEC = "#4B4B4B";
const TEXT_TER = "#979797";

const SUCCESS_PRI = "#1B5E20";
// const SUCCESS_SEC = "#71A165";
const WARNING_PRI = "#D9822B";
// const WARNING_SEC = "#FF9800";
const ERROR_PRI = "#C62828";
// const ERROR_SEC = "#D44D4D";

const WHITE = "#FFFFFF";
const GREY_1 = "#EEEEEE";
const CONTENT_BG = "#F5F5F5";
const GREY_INPUT = "#252525";

export const thirdvoiceMuiTheme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            accent: ACCENT_COLOR,
            white: WHITE,
        },
        grey: {
            contentBg: CONTENT_BG,
        },
        text: {
            primary: TEXT_PRI,
            secondary: TEXT_SEC,
            tertiary: TEXT_TER,
        },
        status: {
            success: SUCCESS_PRI,
            warning: WARNING_PRI, 
            error: ERROR_PRI,
        }
    },
    typography: {
        fontFamily: "Inter, sans-serif",
        allVariants: {
            color: TEXT_PRI,
            letterSpacing: "0.5px",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    borderRadius: "8px",

                    "&.sidenav-tab": {
                        color: TEXT_SEC,
                        fontWeight: "500",
                        fontSize: "16px",
                        justifyContent: "flex-start",
                        padding: "12px 20px",
                        borderRadius: "8px",
                    }
                },
                contained: {
                    boxShadow: "none",
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: WHITE,
                    maxWidth: "100%",
                    padding: "24px 0px 20px",

                    "&.call-details-appbar": {
                        padding: "12px 24px",
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    "&.navbar-select": {
                        backgroundColor: CONTENT_BG,
                        color: TEXT_SEC,
                        fontSize: "14px",
                        fontWeight: "500",
                        minWidth: "25%",
                        borderRadius: "8px",

                        ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                            padding: "8px 16px"
                        },

                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none"
                        }
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    width: "100%",
                    tableLayout: "auto",
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&.cl-table-row": {
                        cursor: "pointer",
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingLeft: "24px",
                    paddingRight: "24px",
                },
                head: {
                    fontWeight: "600",
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: TEXT_PRI,
                    padding: 0
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: GREY_INPUT,
                        borderWidth: "1px",
                        boxShadow: "none"
                    }
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                }
            }
        },
    }
});