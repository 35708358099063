import { createSlice } from "@reduxjs/toolkit";
import { setDateRangeObj } from "./utilFunctions";

export const CALL_LOGS_KEY = "callLogs";

export const callLogsSlice = createSlice({
    name: CALL_LOGS_KEY,
    initialState: {
        openCallDetails: false,
        currentCallDetails: null,
        dateRange: setDateRangeObj(),
    },
    reducers: {
        setCallDetails: (state, action) => {
            state.openCallDetails = action.payload.openCallDetails;
            state.currentCallDetails = action.payload.currentCallDetails;
        },
        setDateRange: (state, action) => {
            state.dateRange = action.payload[0];
        },
    },
});

export const { setCallDetails, setDateRange } = callLogsSlice.actions;
export default callLogsSlice.reducer;