// all routes for the site

import { Route, Routes } from 'react-router';
import { Login, Overview, Assistants } from '../pages';
import CallLogs from '../pages/callLogs/CallLogs';
import { Box, Typography } from '@mui/material';
import PrivateRoutes from './privateRoutes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { checkIsAuth } from '../pages/auth/authUtils';
import { setIsAuth } from '../pages/auth/authSlice';
import { getCookie } from '../utils/cookies';
import { setUserData } from '../redux/userSlice';

export default function SiteRoutes() {
	const dispatch = useDispatch();

	useEffect(() => {
		const isAuth = checkIsAuth();
		dispatch(setIsAuth(isAuth));

		let userData = getCookie('userData');

		if (isAuth && userData) {
			userData = JSON.parse(userData);
			dispatch(setUserData(userData));
		}
	}, []);

	return (
		<Routes>
			<Route
				path="/login"
				element={<Login />}
			/>

			<Route path="/" element={<PrivateRoutes />}>
				<Route path="/" element={<Overview />}/>
				<Route path="/overview" element={<Overview />}/>
				<Route path="/assistants" element={<Assistants />}/>
				<Route path="/call-logs" element={<CallLogs />}/>
				<Route path="/call-logs/:id" element={<CallLogs />}/>
			</Route>
		</Routes>
	);
}
