import axios from "axios"

export const getBrowserLocation = (cb) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => cb({ lat: position.coords.latitude, lon: position.coords.longitude }),
            (error) => console.error("Error getting location", error)
        );
    } else {
        console.error("Geolocation is not supported by this browser.");
    }
}

export const getCountryIso = async (lat, lon, cb) => {
    const apiUrl = "https://nominatim.openstreetmap.org/reverse"

    try {
        const { data } = await axios.get(
            apiUrl,
            {params: {lat, lon, format: "jsonv2"}}
        )

        if (data) {
            cb (data.address.country_code)
        } else {
            console.error("Error getting country code")
        }
    } catch (error) {
        console.error("Error getting country code", error)
    }
}

