import Request from '../../utils/api';
import { overviewSection1, overviewSection2 } from '../../utils/apiRoutes';
import { format, addDays } from 'date-fns';

const api = new Request();

export const fetchOverViewSection = async (params) => {
	const { response } = await api.get(overviewSection1, params);
	return response;
};
export const fetchGraphData = async (params) => {
	const { response } = await api.get(overviewSection2, params);
	return response;
};

export const formateDate = (date, formateType) => {
	const str = format(date, formateType);
	return str;
};

export const setIntialState = () => {
	return [{ startDate: addDays(new Date(), -30), endDate: new Date(), key: 'selection' }];
};
