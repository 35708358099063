// Overview
export const overviewSection2 = '/v1/dashboard/section2';
export const overviewSection1 = '/v1/dashboard/section1';
export const loginRoute = '/v1/user/login';
export const getCallLogsRoute = '/v1/vapi/calllogs';

// AUTH
export const LOGIN = '/v1/user/login';
export const VERIFY_OYP = '/v1/user/verifyOtp';

// SIDENAV
export const SIDENAV_CONFIG = '/v1/config/sidenav';

// NAVBAR
export const USER_ORGS = '/v1/user/userOrgs';

// DASHBOARD
export const VAPI_CALL_LOGS = '/v1/vapi/calllogs';

// ASSISTANTS
export const ASSISTANTS_LIST = '/v1/vapi/assistants';
