import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/auth/authSlice';
import userReducer from '../redux/userSlice';
import overviewReducer from '../pages/overview/overviewSlice';
import callLogsReducer from '../pages/callLogs/callLogsSlice';

export const REDUCER_KEYS = {
	AUTH: 'auth',
	USER: 'user',
	OVERVIEW: 'overview',
	CALL_LOGS: 'callLogs'
};

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		overview: overviewReducer,
		callLogs: callLogsReducer
	},
});
