import { Box, Typography } from '@mui/material';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

function BarGraph({ data }) {
	return (
		<Box
			style={{
				// height: '100%',
				backgroundColor: 'white',
				padding: '16px',
				borderRadius: '8px',
			}}
		>
			<Typography
				align="center"
				sx={{ fontSize: '16px', marginBottom: '16px' }}
			>
				{'Average Number Of New Patient Leads By Day'}
			</Typography>
			<ResponsiveContainer
				width={'100%'}
				height={380}
				style={{
					backgroundColor: 'white',
				}}
			>
				<BarChart
					width={600}
					height={60}
					data={data}
				>
					<XAxis
						dataKey="title"
						stroke="#4b4b4b"
						fontSize={12}
					></XAxis>
					<YAxis stroke="#4b4b4b" fontSize={12} />
					<Tooltip wrapperStyle={{ backgroundColor: '#ccc' }} />
					<CartesianGrid strokeDasharray="3 3" />
					<Bar
						dataKey={'repeatPatient'}
						fill="#88A4D7"
						stackId="a"
					/>
					<Bar
						dataKey={'newLead'}
						fill="#D3A0B9"
						stackId="a"
					/>
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
}

export default BarGraph;
